import React from 'react';

const RightWidget = () => {
    return (
        <div className='log_RightWidget_wrap'>
            <div className='logoContain'>
                <img src={`assets/images/dummy-logo2.png`} alt='Logo' className='side__logo' />
            </div>
            <div className='secondaryImg'>
                <img src={`assets/images/login-dashboard.png`} alt='dashboard' />
            </div>
            <div className='content_wrap'>
                <h2>Welcome Back</h2>
                <p>Enter your credentials to access your account</p>
            </div>

        </div>
    )
}

export default RightWidget
